/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import { hidden } from "../styles/utils"

const icons = {
  //wave
  triangle: {
    shape: (
      <React.Fragment>
      <path fill="currentColor" d="M94.42,143.113c8.82-8.82,23.171-8.82,31.993,0c24.416,24.416,64.145,24.416,88.561,0
      c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c24.416,24.416,64.145,24.416,88.561,0
      c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c11.828,11.828,27.554,18.342,44.28,18.342
      c16.728,0,32.453-6.514,44.281-18.342c7.811-7.811,7.81-20.474-0.001-28.284c-7.811-7.811-20.474-7.811-28.284,0
      c-4.272,4.273-9.953,6.626-15.996,6.626s-11.724-2.354-15.996-6.625c-11.827-11.828-27.554-18.342-44.28-18.343
      c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342c-8.82,8.821-23.174,8.819-31.992,0.001
      c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342
      c-8.82,8.821-23.174,8.82-31.992,0c-24.417-24.417-64.146-24.417-88.562,0c-8.82,8.82-23.172,8.82-31.994,0
      c-7.811-7.811-20.474-7.811-28.284,0s-7.81,20.474,0.001,28.284C30.274,167.529,70.002,167.529,94.42,143.113z"/>
      <path fill="currentColor" d="M427.799,216.829c-4.272,4.273-9.953,6.626-15.996,6.626s-11.724-2.354-15.996-6.625
      c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342
      c-8.82,8.821-23.174,8.819-31.992,0.001c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0
      c-16.726,0-32.453,6.515-44.281,18.342c-8.82,8.821-23.174,8.82-31.992,0c-24.417-24.417-64.146-24.417-88.562,0
      c-8.82,8.82-23.172,8.82-31.994,0c-7.811-7.811-20.474-7.811-28.284,0s-7.81,20.474,0.001,28.284
      c24.416,24.416,64.144,24.416,88.562,0c8.82-8.82,23.171-8.82,31.993,0c24.416,24.416,64.145,24.416,88.561,0
      c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c24.416,24.416,64.145,24.416,88.561,0
      c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c11.828,11.828,27.554,18.342,44.28,18.342
      c16.728,0,32.453-6.514,44.281-18.342c7.811-7.811,7.81-20.474-0.001-28.284C448.272,209.018,435.609,209.018,427.799,216.829z"/>
      <path fill="currentColor" d="M427.799,318.829c-4.272,4.273-9.953,6.626-15.996,6.626s-11.724-2.354-15.996-6.625
      c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0c-16.726,0-32.453,6.515-44.281,18.342
      c-8.82,8.821-23.174,8.819-31.992,0.001c-11.827-11.828-27.554-18.342-44.28-18.343c-0.002,0,0,0-0.001,0
      c-16.726,0-32.453,6.515-44.281,18.342c-8.82,8.82-23.174,8.821-31.992,0c-24.417-24.417-64.146-24.417-88.562,0
      c-8.82,8.82-23.172,8.82-31.994,0c-7.811-7.811-20.474-7.811-28.284,0s-7.81,20.474,0.001,28.284
      c24.416,24.416,64.144,24.416,88.562,0c8.82-8.82,23.171-8.82,31.993,0c24.416,24.416,64.145,24.416,88.561,0
      c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c24.416,24.416,64.145,24.416,88.561,0
      c4.273-4.273,9.954-6.626,15.997-6.626s11.724,2.354,15.997,6.626c11.828,11.828,27.554,18.342,44.28,18.342
      c16.728,0,32.453-6.514,44.281-18.342c7.811-7.811,7.81-20.474-0.001-28.284C448.272,311.018,435.609,311.018,427.799,318.829z"/>
      
      </React.Fragment>
      ),
      viewBox: `0 0 461.941 461.941`,
      // shape: (
      //   <polygon
      //     strokeWidth="1px"
      //     strokeLinejoin="round"
      //     strokeMiterlimit="10"
      //     points="14.921,2.27 28.667,25.5 1.175,25.5 "
      //   />
      // ),
      // viewBox: `0 0 30 30`,
    },
    //scissors
    circle: {
      shape: (
        <React.Fragment>
        <path d="m223.66 189.87c40.11-55.31 48.78-95.731 62.36-91.863 10.62 3.023 5.52 12.113 33.59 18.523 26.95 6.16 54.48-15.96 60.2-42.656 7.62-26.163-4.73-59.047-32.36-67.165-27.34-9.8952-59.93 6.188-69.66 33.419-14.09 31.13-21.32 72.242-66.88 107.53l-34.96 27.09-121.01 170.84c-13.534 22.08-21.658 51.47-4.115 79.44l172.84-235.16zm129.82-103.12c-23.31 23.251-64.54 9.071-64.47-21.965-1.65-28.553 33.06-54.558 58.85-38.26 21.73 11.428 21.57 44.309 5.62 60.225z"/>
        <path d="m161.84 191.37c-39.99-55.39-48.57-95.832-62.161-91.994-10.627 3.004-5.543 12.104-33.624 18.454-26.962 6.09-54.445-16.09-60.106-42.796-7.5603-26.18 4.862-59.036 32.508-67.093 27.363-9.8338 59.916 6.322 69.583 33.575 14.03 31.161 21.16 72.294 66.64 107.68l34.91 27.16 120.62 171.11c13.49 22.12 21.54 51.52 3.94 79.45l-172.31-235.55zm-129.59-103.4c23.254 23.302 64.514 9.214 64.512-21.821 1.714-28.55-32.933-54.632-58.766-38.391-21.747 11.379-21.664 44.26-5.746 60.212z"/>
        </React.Fragment>
        ),
        viewBox: `0 0 385.79 430.69`,
      },
      //wind
      arrowUp: {
        shape: (
          <path d="M28.69,53.38c-1.61,0-2.91-1.3-2.91-2.91c0-1.61,1.3-2.91,2.91-2.91h51.37c0.21,0,0.42,0.02,0.62,0.07 c1.84,0.28,3.56,0.8,5.1,1.63c1.7,0.92,3.15,2.19,4.27,3.89c3.85,5.83,3.28,11.24,0.56,15.24c-1.77,2.61-4.47,4.55-7.45,5.57 c-3,1.03-6.32,1.13-9.32,0.03c-4.54-1.66-8.22-5.89-8.76-13.55c-0.11-1.6,1.1-2.98,2.7-3.09c1.6-0.11,2.98,1.1,3.09,2.7 c0.35,4.94,2.41,7.56,4.94,8.48c1.71,0.62,3.67,0.54,5.48-0.08c1.84-0.63,3.48-1.79,4.52-3.32c1.49-2.19,1.71-5.28-0.61-8.79 c-0.57-0.86-1.31-1.51-2.18-1.98c-0.91-0.49-1.97-0.81-3.13-0.99H28.69L28.69,53.38z M15.41,27.21c-1.61,0-2.91-1.3-2.91-2.91 c0-1.61,1.3-2.91,2.91-2.91h51.21c1.17-0.18,2.23-0.5,3.14-0.99c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79 c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7 c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57 c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H15.41 L15.41,27.21z M2.91,40.3C1.3,40.3,0,38.99,0,37.39c0-1.61,1.3-2.91,2.91-2.91h107.07c1.17-0.18,2.23-0.5,3.13-0.99 c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08 c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55 c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89 c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H2.91L2.91,40.3z"/>
          ),
          viewBox: `0 0 122.88 74.78`,
          // shape: (
          //   <React.Fragment>
          //     <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
          //     {` `}
          //     <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
          //   </React.Fragment>
          // ),
          // viewBox: `0 0 30 42`,
        },
        //snow
        upDown: {
          shape: (
            <path d="M23 47.689v-6.342l-3.357 1.992-1.643-1.034v-2.229l5-2.986v-4.168l-4 2.451v-4.416l-4 2.094v5.99l-1.653 1.23-1.347-1.111v-4.012l-5.574 3.122-2.426-.999v-2.529l5.685-3.17-3.685-1.822v-2.32l2.123-1.127 5.214 3.068 3.612-2.084-.082-.065-3.665-2.123 3.568-2.228-3.577-2.083-5.213 3.052-1.98-.969v-2.307l3.542-1.978-5.542-3.053v-2.529l2.321-1.114 5.679 3.197v-4.076l1.485-1.127 1.943 1.18-.056 6.105 3.673 2.122.033-4.311 3.922 2.099v-4.167l-5-2.988v-2.214l1.643-1.05 3.357 1.992v-6.328l1.994-1.324 2.006 1.324v6.328l3.906-2.031 2.094 1.219v1.992l-6 3.08v4.167l4-2.267v4.534l4-2.084v-6.524l1.455-.866 1.545.865v4.167l5.842-3.08 2.158 1.218v2.359l-5.495 3.17 3.495 1.954v2.254l-1.83.996-5.327-3.158-3.679 2.346 3.549 2.228-3.659 2.122 3.772 1.992 5.389-2.986 1.785 1.216v2.15l-3.32 1.887 5.32 3.17v2.49l-2.522 1.037-5.478-2.988v3.955l-1.52 1.049-1.48-1.049v-6.002l-4-2.213v4.168l-4-2.268v4.168l5 2.986v2.359l-1.647.904-3.353-1.99v6.342l-2.006 1.31-1.994-1.311zm-1.466-22.597l1.886 2.908h3.514l1.613-2.908-1.704-3.092h-3.514l-1.795 3.092z"/>
            ),
            viewBox: `0 0 50 50`,
            // shape: (
            //   <React.Fragment>
            //     <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
            //     <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
            //   </React.Fragment>
            // ),
            // viewBox: `0 0 30 44.58`,
          },
          //jellyfish
          box: {
            shape: (
              <React.Fragment>
              <path d="M96.5,181.1c-3.5-6-6.5-11.2-6.5-22.2c0-11,3-16.2,6.5-22.2c3.2-5.4,6.7-11.5,7.8-21.5c0.1-0.8,0-1.7-0.2-2.4H92.3
              c-0.1,0.3-0.2,0.7-0.2,1.1c-0.8,7.4-3.4,11.8-6.2,16.7c-3.8,6.6-8.2,14.1-8.2,28.4c0,14.3,4.4,21.8,8.2,28.4
              c2.8,4.9,5.3,9.2,6.2,16.5c0.2,3.2,2.8,5.8,6.1,5.8c3.4,0,6.1-2.8,6.1-6.1c0-0.1,0-0.4,0-0.4C103.3,192.7,99.7,186.6,96.5,181.1z"
              />
              <path d="M55.8,153.6c0-11,3-16.2,6.5-22.2c2.9-4.9,6-10.3,7.4-18.6H57.2c-1.2,5-3.2,8.5-5.5,12.4c-3.8,6.6-8.2,14.1-8.2,28.3
              c0,14.3,4.4,21.8,8.2,28.3c3.5,6,6.5,11.2,6.5,22.2c0,11-3,16.2-6.5,22.2c-2.8,4.8-5.9,10.1-7.3,18.2c-0.7,3.8,2.2,7.3,6,7.3h0
              c3,0,5.5-2.2,6-5.1c1.1-5.9,3.3-9.8,5.9-14.2c3.8-6.6,8.2-14.1,8.2-28.4c0-14.3-4.4-21.8-8.2-28.3
              C58.8,169.7,55.8,164.5,55.8,153.6z"/>
              <path d="M145.2,121.6c0-3.3-0.3-6.2-0.8-8.8h-12.6c0,0,0,0.1,0,0.1c0.7,2.5,1.1,5.3,1.1,8.7c0,10.6-3.8,15.7-8.2,21.6
              c-5,6.7-10.6,14.3-10.6,28.9c0,14.7,5.6,22.2,10.6,28.9c3.5,4.8,6.7,9,7.8,16.1c0.5,3,3,5.3,6.1,5.3h0c3.8,0,6.6-3.4,6.1-7.1
              c-1.5-10.1-6-16.1-10-21.6c-4.4-5.9-8.2-11-8.2-21.6c0-10.6,3.8-15.7,8.2-21.6C139.6,143.8,145.2,136.2,145.2,121.6z"/>
              <path d="M114.5,3.5c-45.2,0-81.9,31.3-81.9,69.9c0,6.4,1,12.6,2.9,18.4c1.8,5.7,7,9.6,13,9.6h132.2c5.9,0,11.1-3.9,13-9.6
              c1.9-5.9,2.9-12.1,2.9-18.4C196.4,34.8,159.7,3.5,114.5,3.5z"/>
              <path d="M188,153.6c0-14.7-5.6-22.2-10.6-28.9c-2.8-3.7-5.3-7.2-6.8-11.9H158c1.8,8.6,5.9,14.2,9.6,19.2c4.4,5.9,8.2,11,8.2,21.6
              c0,10.6-3.8,15.7-8.2,21.6c-5,6.7-10.6,14.3-10.6,28.9c0,14.7,5.6,22.2,10.6,28.9c3.2,4.3,6,8.1,7.3,13.9c0.6,2.8,3.1,4.8,6,4.8h0
              c4,0,6.9-3.7,6-7.6c-1.9-8.2-5.8-13.6-9.5-18.4c-4.4-5.9-8.2-11-8.2-21.6c0-10.6,3.8-15.7,8.2-21.6
              C182.4,175.8,188,168.2,188,153.6z"/>
              </React.Fragment>
              
              ),
              viewBox: `0 0 224 256`,
              // shape: (
              //   <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
              // ),
              // viewBox: `0 0 30 30`,
            },
            //van
            hexa: {
              shape: (
                <React.Fragment>
                <path fill="currentColor" d="m409.326,211.562c0-4.142-3.357-7.5-7.5-7.5h-333.652c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h333.652c4.143,0 7.5-3.358 7.5-7.5z"/>
                <path fill="currentColor" d="m116.411,362.655h-48.911c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h48.911c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"/>
                <path fill="currentColor" d="m313.589,362.655h-157.178c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h157.178c4.143,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z"/>
                <path fill="currentColor" d="m402.5,362.655h-48.911c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5h48.911c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"/>
                <path fill="currentColor" d="m296.304,299.978l-7.914,21.131h-106.78l-7.914-21.131c-1.452-3.878-5.772-5.845-9.654-4.393-3.879,1.453-5.846,5.775-4.393,9.654l9.738,26c1.097,2.929 3.896,4.869 7.023,4.869h117.178c3.127,0 5.927-1.94 7.023-4.869l9.738-26c1.453-3.879-0.514-8.202-4.393-9.654-3.879-1.454-8.2,0.513-9.652,4.393z"/>
                <path fill="currentColor" d="M462.5,154.062c-4.143,0-7.5,3.358-7.5,7.5v42.5h-16.431L413.401,86.019c-0.005-0.024-0.01-0.048-0.016-0.073   l-12.889-60.449C397.45,11.2,383.611,0,368.992,0H101.008C86.39,0,72.551,11.199,69.502,25.497L56.61,85.963   c-0.01,0.044-25.179,118.099-25.179,118.099H15v-42.5c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50c0,4.142,3.357,7.5,7.5,7.5   H30V390.2c0,12.563,8.472,23.178,20,26.452V462.5c0,4.142,3.357,7.5,7.5,7.5h68.911c4.143,0,7.5-3.358,7.5-7.5v-22.25   c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V455H65v-37.3h340V455h-53.911v-14.75c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5   v22.25c0,4.142,3.357,7.5,7.5,7.5H412.5c4.143,0,7.5-3.358,7.5-7.5v-45.848c11.528-3.274,20-13.889,20-26.452V219.062h22.5   c4.143,0,7.5-3.358,7.5-7.5v-50C470,157.419,466.643,154.062,462.5,154.062z M84.172,28.625C85.72,21.367,93.587,15,101.008,15   h267.984c7.421,0,15.288,6.366,16.835,13.625L396.781,80H73.218L84.172,28.625z M412.5,402.7h-355c-6.893,0-12.5-5.607-12.5-12.5   v-54.091h91.411c2.459,0,4.763-1.206,6.164-3.227c1.4-2.021,1.722-4.601,0.859-6.904l-9.738-26   c-3.975-10.607-15.959-18.916-27.285-18.916H72.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h33.911   c5.129,0,11.438,4.375,13.238,9.178l5.943,15.869H45V212.352L70.02,95h329.959L425,212.352v108.756h-80.593l5.943-15.869   c1.8-4.804,8.109-9.178,13.238-9.178H397.5c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-33.911   c-11.326,0-23.311,8.309-27.285,18.916l-9.738,26c-0.862,2.303-0.541,4.883,0.859,6.904c1.401,2.021,3.705,3.227,6.164,3.227H425   V390.2C425,397.092,419.393,402.7,412.5,402.7z"/>
                </React.Fragment>
                ),
                viewBox: `0 0 470 470`,
                // shape: (
                //   <polygon
                //     strokeLinejoin="round"
                //     strokeMiterlimit="10"
                //     points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
                //   />
                // ),
                // viewBox: `0 0 30 30`,
              },
              // butcher knife
              cross: {
                shape: (
                  <React.Fragment>
                  <path d="M274.295,81.922c3.659,0,7.099-1.425,9.686-4.013c2.587-2.587,4.012-6.027,4.012-9.687c0-3.659-1.425-7.099-4.012-9.685
                  c-2.587-2.588-6.027-4.013-9.686-4.013s-7.099,1.425-9.686,4.012c-2.587,2.588-4.013,6.027-4.013,9.686
                  c0,3.659,1.425,7.1,4.013,9.688C267.195,80.497,270.635,81.922,274.295,81.922z M270.972,64.9c0.888-0.888,2.067-1.376,3.322-1.376
                  s2.435,0.488,3.323,1.377c0.887,0.887,1.376,2.066,1.376,3.321c0,1.255-0.489,2.436-1.376,3.323
                  c-0.887,0.887-2.067,1.376-3.322,1.376c-1.255,0-2.436-0.489-3.323-1.376c-0.888-0.888-1.376-2.068-1.376-3.323
                  C269.595,66.968,270.084,65.788,270.972,64.9z"/>
                  <path d="M391.119,144.304l-97.376-97.376c-5.348-5.349-12.454-8.294-20.009-8.294c-8.633,0-16.683,3.858-22.085,10.584
                  l-21.293,26.512C146.559,178.846,17.676,288.065,13.678,291.442c-15.19,11.564-18.191,33.323-6.672,48.568
                  c6.623,8.766,16.731,13.793,27.73,13.793c5.795,0,11.536-1.465,16.647-4.26c0.029-0.016,0.059-0.031,0.087-0.048
                  c5.313-2.975,7.77-9.294,5.84-15.027c-0.108-0.319-0.207-0.642-0.298-0.966c-2.155-7.69,0.483-15.868,6.722-20.834
                  c24.624-19.606,46.342-39.352,68.041-61.879l76.428,76.428c1.602,1.602,4.142,1.763,5.933,0.38
                  c62.561-48.314,119.217-104.189,168.396-166.078l8.928-11.234C392.883,148.494,392.737,145.921,391.119,144.304z M237.357,81.386
                  l21.31-26.532c3.685-4.588,9.176-7.22,15.067-7.22c5.151,0,9.997,2.01,13.645,5.657l75.643,75.643l-6.43,8.092
                  c-47.905,60.286-102.988,114.8-163.739,162.113l-82.786-82.785C151.111,177.211,198.921,128.683,237.357,81.386z M58.129,305.628
                  c-9.076,7.226-12.916,19.12-9.782,30.302c0.133,0.476,0.279,0.95,0.436,1.415c0.537,1.595-0.188,3.434-1.688,4.287
                  c-0.01,0.006-0.02,0.011-0.028,0.016c-3.761,2.064-8.024,3.156-12.33,3.156c-8.152,0-15.642-3.725-20.549-10.219
                  c-8.552-11.318-6.301-27.485,5.017-36.038c0.063-0.047,0.125-0.097,0.185-0.147c0.632-0.531,31.167-26.327,72.175-64.592
                  l22.316,22.316C96.179,273.684,78.125,289.706,58.129,305.628z M120.232,249.748l-22.096-22.096
                  c1.785-1.678,3.587-3.378,5.404-5.098l21.867,21.867C123.683,246.212,121.958,247.991,120.232,249.748z M375.487,155.92
                  c-47.907,60.288-102.976,114.818-163.724,162.129l-12.499-12.499c60.964-47.592,116.258-102.372,164.375-162.924l5.789-7.286
                  l12.489,12.489L375.487,155.92z"/>
                  </React.Fragment>
                  
                  ),
                  viewBox: `0 0 392.437 392.437`,
                  // shape: (
                  //   <path
                  //     strokeWidth="3px"
                  //     d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
                  //   />
                  // ),
                  // viewBox: `0 0 100 100`,
                },
              }
              
              type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"
              
              type SVGProps = {
                stroke?: boolean
                color?: string | number | any
                width: number
                icon: IconType
                left: string
                top: string
                hiddenMobile?: boolean
              }
              
              const Svg = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false }: SVGProps) => (
                <svg
                sx={{
                  position: `absolute`,
                  stroke: stroke ? `currentColor` : `none`,
                  fill: stroke ? `none` : `currentColor`,
                  display: hiddenMobile ? hidden : `block`,
                  color,
                  width,
                  left,
                  top,
                }}
                viewBox={icons[icon].viewBox}
                >
                {icons[icon].shape}
                </svg>
                )
                
                export default Svg
                